import {mapActions, mapGetters, mapMutations} from "vuex";
import dropdownSelect from "@/modules/filter-products/section/filters/components/dropdown-select/index.vue";
import checkboxSelect from "@/modules/filter-products/section/filters/components/checkbox-select/index.vue";
import attributeSelect from "@/modules/filter-products/section/filters/components/attribute-select/index.vue";
import colorSelect from "@/modules/filter-products/section/filters/components/color-select/index.vue";
import multipleSelect from "@/modules/filter-products/section/filters/components/multiple-select/index.vue";
import 'vue-range-component/dist/vue-range-slider.css'
import VueRangeSlider from 'vue-range-component'
import _ from "lodash";

import {filter} from "@/mixins/filter.js";

export default {
    name: "main-filters",
    components: {
        dropdownSelect,
        checkboxSelect,
        attributeSelect,
        colorSelect,
        multipleSelect,
       VueRangeSlider
    },
    mixins: [filter],
    data() {
        return {
            range: [],
            number: 10,
            propertySelect: [],
            currentProperty: '',
            collectSlug: [],
            searchValue: '',
            rengeKey: 0,
            max: 100,
        }
    },
    watch: {
        range() {
            this.sendRequest()
        },
        '$route.query': function () {
            this.sendRequest()
        },

    },
    created() {
        this.enableCross = false
        this.tooltipMerge = false
        this.formatter = value => `${value ? value : 0}`
        this.processStyle = {
            backgroundColor: 'black',

        }
        this.tooltipStyle = {
            backgroundColor: 'black',
            borderColor: 'black',
        }
        this.bgStyle = {
            backgroundColor: '#5B99EB',
            boxShadow: 'inset 0.5px 0.5px 3px 1px rgba(0,0,0,.36)',

        }

        this.getFilters(this.$route.params.slug).then(() => {

            this.rengeKey += 1


            this.range.push(0)
            this.range.push(this.productFilters.prices.max)
            this.max = this.productFilters.prices.max

        })
    },
    computed: {
        ...mapGetters({
            productFilters: 'catalog/productFilters',
            pageContent: 'catalog/currentCategory',
            showMobileFilters:'catalog/showMobileFilters'
        })
    },
    methods: {
        ...mapMutations({
            hideMobileFilters:'catalog/SHOW_MOBILE_FILTERS'
        }),
        ...mapActions({
            getFilters: 'catalog/GET_PRODUCT_FILTERS',
            fetchFilters: 'catalog/GET_FILTERED_PRODUCTS',
            searchProduct: 'catalog/PRODUCT_SEARCH'
        }),
        search: _.debounce(function () {
            let obj = {}
            obj.search = this.searchValue
            obj.slug = this.$route.params.slug
            if (this.searchValue.length >= 3) {
                this.searchProduct(obj)
            }
        }, 500),
        sendRequest: _.debounce(function () {
            if (Object.values(this.$route.query).length) {
                let values = []
                for (let key in this.$route.query) {
                    let string = `&${[key]}=${this.$route.query[key]}`
                    values.push(string)
                }

                this.fetchFilters({
                    slug: this.$route.params.slug,
                    data: values,
                    price_min: this.range[0],
                    price_max: this.range[1],
                    limit: 12,
                    page: 1
                })
            } else {
                // let arr = []
                // this.productFilters.filters.forEach(el => {
                //   console.log(el,'el');
                //   let string = `&${[el.attribute.id]}=${el.attribute.options.data[0].id}`
                //   arr.push(string)
                // })

                this.fetchFilters({
                    slug: this.$route.params.slug,
                    data: '',
                    price_min: this.range[0],
                    price_max: this.range[1],
                    limit: 12,
                    page: 1
                })

                // this.$router.replace({
                //   name: 'filter',
                //   params: this.$route.params,
                //   query: encodeURIComponent(arr.join(''))
                // })

            }

        }, 700),
    }
}
