import {directive as onClickaway} from 'vue-clickaway';
import dropdownSelect from "@/modules/filter-products/section/filters/components/dropdown-select/index.vue";
import checkboxSelect from "@/modules/filter-products/section/filters/components/checkbox-select/index.vue";
import attributeSelect from "@/modules/filter-products/section/filters/components/attribute-select/index.vue";
import colorSelect from "@/modules/filter-products/section/filters/components/color-select/index.vue";

import {filter} from "@/mixins/filter.js";
import Vue from "vue";

export default {
  directives: {
    onClickaway: onClickaway,
  },
  components: {
    dropdownSelect,
    checkboxSelect,
    multipleSelect: () => import("@/modules/filter-products/section/filters/components/multiple-select/index.vue"),
    attributeSelect,
    colorSelect
  },
  mixins: [filter],
  props: {
    list: {
      type: Array,
      default: () => []
    }
    ,
    itemValue: {
      type: String,
      default: 'default'
    },
    itemTitle: {
      type: String,
      default: 'name'
    },
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      openSelect: true,
      selectedItem: this.value,
      collectSlug: [],
      selectedFilter: [],
      progress: false,
      currentProperty: '',
      title: ''
    }
  },
  watch: {
    value() {
      this.changeSelect(this.value)
    }
  },
  created() {
    this.selectedItem = this.value;

  },
  mounted() {
    console.log(this.item.attributes.data);
    // setTimeout(() => {

      // Vue.nextTick(() => {

        // let firstSelect = Array.from(this.$refs['firsSelect'])
        // firstSelect[0].$el.classList.add('firstSelectElement')
        // this.title = firstSelect[0].$options.propsData.name


      // })
    // }, 1000)
  },
  methods: {

    changeEvent(item) {
      this.$emit('change-event', item)
    },
    selectCheckbox(item) {
      let checkbox = this.$refs[item]
      checkbox[0].classList.toggle('activeCheck')
    },
    changeSelect(item) {
      this.selectedItem = item;
      this.$emit('input', item);
    },
    away: function () {
      this.openSelect = false
    },
    toggleSelect() {
      !this.openSelect ? this.openSelect = true : this.openSelect = false
    }
  }
}
